import React, {Component} from "react";
import {API} from "aws-amplify";
import {Form, Row, Col} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./EditGoal.css";

export default class EditGoal extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            isLoading: null,
            isDeleting: null,
            goal: null,
            title: null,
            description: null,
            target: null,
            units: null,
            size: null
        };
    }

    async componentDidMount() {
        try {
            const goal = await this.getGoal();

            this.setState({
                goal: goal,
                title: goal.title,
                description: goal.description,
                target: parseInt(goal.target),
                units: goal.units,
                size: parseInt(goal.size),
                status: goal.status
            });
        } catch (e) {
            alert(e);
        }
    }

    getGoal() {
        return API.get("goals", `/goals/${this.props.match.params.id}`);
    }

    saveGoal(goal) {
        return API.put("goals", `/goals/${this.props.match.params.id}`, {body: goal});
    }

    deleteGoal() {
        return API.del("goals", `/goals/${this.props.match.params.id}`);
    }

    validateForm() {
        return (this.state.title.length > 0
            && this.state.target > 0
            && this.state.units.length > 0
            && this.state.size > 0);
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true});

        try {
            let goal = {
                title: this.state.title,
                target: parseInt(this.state.target),
                units: this.state.units,
                size: parseInt(this.state.size),
                status: this.state.status
            };
            if (this.state.description && (this.state.description.length > 0)) 
                goal.description = this.state.description;
            await this.saveGoal(goal);
            this
                .props
                .history
                .push("/");
        } catch (e) {
            alert(e);
            this.setState({isLoading: false});
        }
    }

    handleDelete = async event => {
        event.preventDefault();

        const confirmed = window.confirm("Are you sure you want to delete this goal?");

        if (!confirmed) {
            return;
        }

        this.setState({isDeleting: true});

        try {
            await this.deleteGoal();
            this
                .props
                .history
                .push("/");
        } catch (e) {
            alert(e);
            this.setState({isDeleting: false});
        }
    }

    render() {
        return (
            <div className="EditGoal">
                <h1>Edit Goal</h1>
                {this.state.goal && <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="title">
                        <Form.Label>Goal Name</Form.Label>
                        <Form.Control
                            onChange={this.handleChange}
                            value={this.state.title}
                            type="text"
                            placeholder="Give your goal a name, like 'graduate college' or 'sell widgets'"
                            size="lg"/>
                    </Form.Group>
                    <Form.Group controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            onChange={this.handleChange}
                            as="select"
                            value={this.state.status}>
                            <option value="active">Active</option>
                            <option value="abandoned">Abandoned</option>
                            <option value="completed">Completed</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Optional Description</Form.Label>
                        <Form.Control
                            onChange={this.handleChange}
                            value={this.state.description || ""}
                            type="textarea"
                            placeholder="Consider describing your goal in a little more detail"/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Defining your goal</Form.Label>
                        <Form.Text>Goals have a target, units, and a size. For example,
                            graduating college might use a target of 120, units of credits, and a size of 3
                            (because usually there are three credits per class.) Target should be evenly
                            divisible by the size and break the target up into somewhere between 10 and 80
                            pieces (aka cats!)</Form.Text>
                    </Form.Group>
                    <Row>
                        <Col md={4}>
                            <Form.Group controlId="target">
                                <Form.Label>Target</Form.Label>
                                <Form.Control
                                    onChange={this.handleChange}
                                    value={this.state.target}
                                    type="text"
                                    placeholder="How many are needed for success?"/>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="units">
                                <Form.Label>Units</Form.Label>
                                <Form.Control
                                    onChange={this.handleChange}
                                    value={this.state.units}
                                    type="text"
                                    placeholder="What kind? (credits, dollars)"/>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="size">
                                <Form.Label>Size of Cat</Form.Label>
                                <Form.Control
                                    onChange={this.handleChange}
                                    value={this.state.size}
                                    type="text"
                                    placeholder="How many per cat?"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <LoaderButton
                        block
                        variant="primary"
                        size="lg"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Save Goal"
                        loadingText="Saving..."/>
                    <LoaderButton
                        block
                        variant="danger"
                        size="lg"
                        isLoading={this.state.isDeleting}
                        onClick={this.handleDelete}
                        text="Delete"
                        loadingText="Deleting…"/>
                </Form>}
            </div>
        );
    }
}
