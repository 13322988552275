import React, {Component, Fragment} from "react";
import {API} from "aws-amplify";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image, Card } from "react-bootstrap";
import "./Home.css";

export default class Home extends Component {
    constructor(props) {
        super(props);

        let status = 'active';
        if (this.props.match.params.status && (this.props.match.params.status === 'abandoned' || this.props.match.params.status === 'completed')) {
            status = this.props.match.params.status;
        }

        this.state = {
            isLoading: true,
            goals: [],
            status: status,
            statusDisplay: status[0].toUpperCase()+status.substring(1)
        };
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const goals = await this.goals();
            this.setState({goals});
        } catch (e) {
            alert(e);
        }

        this.setState({isLoading: false});
    }

    goals() {
        return API.get("goals", `/goals/status/${this.state.status}`);
    }

    renderGoalsList(goals) {
        return goals.map((goal, i) => {
            
                let header = (goal.description) ? goal.description : ('Get '+parseInt(goal.target).toLocaleString()+' '+goal.units);
                let brightCatStyle = {
                    maxWidth: goal.progress.percent+'%'
                }

                return (
                <Card key={goal.goalId} className="one-goal">
                    <Card.Header>{header}</Card.Header>
                    <Card.Body className="text-center">
                        <Card.Title>{goal.title}</Card.Title>
                        <Card.Subtitle>{goal.progress.cats} of {goal.cats}</Card.Subtitle>
                        <Card.Text className="my-3"><Link to={`/goals/${goal.goalId}`} className="cat-holder">
                            <Image src="/cat-shadow.png" className="shadow-cat" />
                            <span className="bright-cat" style={brightCatStyle}><Image src="/cat.png" /></span>
                        </Link></Card.Text>
                        <Card.Link href={`/goals/${goal.goalId}`} title="View this goal" className="h3"><FontAwesomeIcon icon="eye" /></Card.Link>
                        <Card.Link href={`/progress/${goal.goalId}`} title="Add progress" className="h3"><FontAwesomeIcon icon="chart-pie" /></Card.Link>
                        <Card.Link href={`/goals/edit/${goal.goalId}`} title="Edit this goal" className="h3"><FontAwesomeIcon icon="pencil-alt" /></Card.Link>
                    </Card.Body>
                </Card>);
        });
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>GoalCats</h1>
                    <p>Track progress towards goals using cats</p>
                    <div>
                        <Link to="/login" className="btn btn-info btn-lg">Login</Link>
                        <Link to="/signup" className = "btn btn-success btn-lg">Signup</Link>
                </div>
            </div>);
    }

    renderGoals() {
        return (
            <Fragment>
                <h1>{this.state.statusDisplay} Goals <Link to="/goals/new" title="Add new goal"><FontAwesomeIcon icon="plus-circle" className="small" /></Link></h1>
                <div className="goals d-flex flex-wrap justify-content-between mt-4">{!this.state.isLoading && this.renderGoalsList(this.state.goals)}</div>
            </Fragment>);
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated
                    ? this.renderGoals()
                    : this.renderLander()}
            </div>
        );
    }
}