import React, { Component, Fragment } from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { Nav, Navbar, Image } from "react-bootstrap";
import Routes from "./Routes";
import "./App.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt, faEye, faChartPie, faPlusCircle, faSync } from '@fortawesome/free-solid-svg-icons';

library.add(faPencilAlt, faEye, faChartPie, faPlusCircle, faSync);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      version: '1.1.1'
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        console.log(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();

    this.userHasAuthenticated(false);

    this.props.history.push("/login");
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      !this.state.isAuthenticating &&
      <div className="App container">
        <Navbar bg="dark" expand="lg" variant="dark">
          <div className="version">v{this.state.version}</div>
          <Navbar.Brand href="/"><Image src="/cat-small.png" className="d-inline-block align-top" alt="GoalCats" /><div className="app-title">GoalCats</div></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            {this.state.isAuthenticated ?
              <Fragment>
                <Nav>
                  <Nav.Link href="/">Active</Nav.Link>
                  <Nav.Link href="/status/completed">Completed</Nav.Link>
                  <Nav.Link href="/status/abandoned">Abandoned</Nav.Link>
                </Nav>
                <Nav className="ml-auto">
                  <Nav.Link href="#" onClick={this.handleLogout}>Logout</Nav.Link>
                </Nav>
              </Fragment>
              :
              <Nav className="ml-auto">
                <Nav.Link href="/signup">Signup</Nav.Link>
                <Nav.Link href="/login">Login</Nav.Link>
              </Nav>
              }
          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={childProps} />
      </div>
    );
  }
}

export default withRouter(App);
