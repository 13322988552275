import React, {Component} from "react";
import {API} from "aws-amplify";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
// import config from "../config";
import "./Progress.css";

export default class Progress extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            goal: null,
            amount: null,
            notes: null
        };
    }

    async componentDidMount() {
        try {
            const goal = await this.getGoal();

            this.setState({
                goal: goal,
                amount: parseInt(goal.size),
                notes: ""
            });
        } catch (e) {
            alert(e);
        }
    }

    getGoal() {
        return API.get("goals", `/goals/${this.props.match.params.id}`);
    }

    saveProgress(step) {
        return API.put("goals", `/progress/${this.props.match.params.id}`, {body: step});
    }

    validateForm() {
        return (Math.abs(parseInt(this.state.amount)) > 0);
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true});

        try {
            let step = {
                amount: parseInt(this.state.amount),
            };
            if (this.state.notes && (this.state.notes.length > 0)) 
                step.notes = this.state.notes;

            await this.saveProgress(step);
            this
                .props
                .history
                .push("/goals/"+this.state.goal.goalId);
        } catch (e) {
            alert(e);
            this.setState({isLoading: false});
        }
    }

    render() {
        return (
            <div className="Progress">
                <h1>Record Progress</h1>
                {this.state.goal && 
                <Form onSubmit={this.handleSubmit}>
                    <h2>{this.state.goal.title} ({parseInt(this.state.goal.target).toLocaleString()} {this.state.goal.units})</h2>
                    <Form.Group controlId="amount">
                        <Form.Label>How many more {this.state.goal.units} do you have now? (Use a negative amount for a loss.)</Form.Label>
                        <Form.Control
                            onChange={this.handleChange}
                            value={this.state.amount}
                            type="text"
                            placeholder="Enter your progress"
                            size="lg"/>
                    </Form.Group>
                    <Form.Group controlId="notes">
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                            onChange={this.handleChange}
                            value={this.state.notes || ""}
                            type="text"
                            placeholder="Record any additional information about this progress that might be useful later" />
                    </Form.Group>
                    <LoaderButton
                        block
                        variant="primary"
                        size="lg"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Record Progress"
                        loadingText="Saving..."/>
                </Form>}
            </div>
        );
    }
}
