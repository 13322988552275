const dev = {
  STRIPE_KEY: "STRIPE_PROD_SECRET_KEY",
  s3: {
    REGION: "us-east-1",
    BUCKET: "goalcats-api-dev-serverlessdeploymentbucket-cbap52t1rig0"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://633wbi7q2f.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_MzCdH8O8f",
    APP_CLIENT_ID: "30jc6ism2prirjj846m2ug2a5k",
    IDENTITY_POOL_ID: "us-east-1:2a29b2b5-819a-4338-a4bb-49b5e6cdf851"
  }
};

// These haven't been set yet
const prod = {
  STRIPE_KEY: "STRIPE_TEST_SECRET_KEY",
  s3: {
    REGION: "us-east-1",
    BUCKET: "goalcats-api-prod-attachmentsbucket-16p1ex5f9ohe9"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://69i5ecxnth.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_q8hT966iv",
    APP_CLIENT_ID: "2i1skkr2vtj5qanaqt1dlghhe5",
    IDENTITY_POOL_ID: "us-east-1:7f0ee125-05e3-4c28-8d40-558d55f04fb8"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
