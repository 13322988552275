import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import ViewGoal from "./containers/ViewGoal";
import EditGoal from "./containers/EditGoal";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import ResetPassword from "./containers/ResetPassword";
import NewGoal from "./containers/NewGoal";
import Progress from "./containers/Progress";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
// import Settings from "./containers/Settings";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/status/:status" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <AuthenticatedRoute path="/goals/new" exact component={NewGoal} props={childProps} />
    <AuthenticatedRoute path="/goals/:id" exact component={ViewGoal} props={childProps} />
    <AuthenticatedRoute path="/goals/edit/:id" exact component={EditGoal} props={childProps} />
    <AuthenticatedRoute path="/progress/:id" exact component={Progress} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
