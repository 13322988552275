import React, {Component} from "react";
import {API} from "aws-amplify";
import {Form, Row, Col} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
// import config from "../config";
import "./NewGoal.css";

export default class NewGoal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            title: "",
            description: "",
            target: 100,
            units: "things",
            size: 5
        };
    }

    createGoal(goal) {
        return API.post("goals", "/goals", {body: goal});
    }

    validateForm() {
        return (this.state.title.length > 0
            && this.state.target > 0
            && this.state.units.length > 0
            && this.state.size > 0);
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true});

        try {
            let goal = {
                title: this.state.title,
                target: this.state.target,
                units: this.state.units,
                size: this.state.size
            };
            if (this.state.description.length > 0) goal.description = this.state.description;

            await this.createGoal(goal);
            this
                .props
                .history
                .push("/");
        } catch (e) {
            alert(e);
            this.setState({isLoading: false});
        }
    }

    render() {
        return (
            <div className="NewGoal">
                <h1>Add a Goal</h1>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="title">
                        <Form.Label>Goal Name</Form.Label>
                        <Form.Control
                            onChange={this.handleChange}
                            value={this.state.title}
                            type="text"
                            placeholder="Give your goal a name, like 'graduate college' or 'sell widgets'"
                            size="lg"/>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Optional Description</Form.Label>
                        <Form.Control
                            onChange={this.handleChange}
                            value={this.state.description}
                            type="textarea"
                            placeholder="Consider describing your goal in a little more detail"/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Defining your goal</Form.Label>
                        <Form.Text>Goals have a target, units, and a size. For example, graduating college might use a target of 120, units of credits, and a size of 3 (because usually there are three credits per class.) Target should be evenly divisible by the size and break the target up into somewhere between 10 and 80 pieces (aka cats!)</Form.Text>
                    </Form.Group>
                    <Row>
                        <Col md={4}>
                            <Form.Group controlId="target">
                                <Form.Label>Target</Form.Label>
                                <Form.Control
                                    onChange={this.handleChange}
                                    value={this.state.target}
                                    type="text"
                                    placeholder="How many are needed for success?"/>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="units">
                                <Form.Label>Units</Form.Label>
                                <Form.Control
                                    onChange={this.handleChange}
                                    value={this.state.units}
                                    type="text"
                                    placeholder="What kind? (credits, dollars)"/>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="size">
                                <Form.Label>Size of Cat</Form.Label>
                                <Form.Control
                                    onChange={this.handleChange}
                                    value={this.state.size}
                                    type="text"
                                    placeholder="How many per cat?"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <LoaderButton
                        block
                        variant="primary"
                        size="lg"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Add Goal"
                        loadingText="Adding..."/>
                </Form>
            </div>
        );
    }
}
