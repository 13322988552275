import React, { Component, Fragment } from "react";
import { Image, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from "aws-amplify";
import "./ViewGoal.css";

export default class ViewGoal extends Component {
    constructor(props) {
        super(props);

        this.file = null;
        this.moment = require('moment-timezone');

        this.state = {
            isLoading: null,
            isDeleting: null,
            goal: null,
            title: null,
            description: null,
            target: null,
            units: null,
            size: null
        };
    }

    async componentDidMount() {
        try {
            const goal = await this.getGoal();

            this.setState({
                goal: goal,
                title: goal.title,
                description: goal.description,
                target: parseInt(goal.target),
                units: goal.units,
                size: parseInt(goal.size),
                status: goal.status
            });
        } catch (e) {
            alert(e);
        }
    }

    getGoal() {
        return API.get("goals", `/goals/${this.props.match.params.id}`);
    }

    render() {

        let lead = '';
        let cats = [];
        let progress = [];

        if (this.state.goal) {
            if (this.state.description && this.state.description.length > 0) {
                lead = <p className="lead">{this.state.description}</p>;
            }

            if (this.state.goal.progress.cats > 0) {
                for(let i=0; i<this.state.goal.progress.cats; i++) {
                    cats.push(<Image src="/cat.png" className="one-cat bright-cat p-1 py-2 p-md-2" key={i} />);
                }
            }

            if (this.state.goal.progress.cats < this.state.goal.cats) {
                for(let i=this.state.goal.progress.cats; i<this.state.goal.cats; i++) {
                    cats.push(<Image src="/cat-shadow.png" className="one-cat shadow-cat p-1 py-2 p-md-2" key={i} />);
                }
            }

            if (this.state.goal.steps.length > 0) {
                for(let i=this.state.goal.steps.length - 1; i>=0; i--) {
                    let amount = parseInt(this.state.goal.steps[i].amount).toLocaleString();
                    let notes = (this.state.goal.steps[i].notes && this.state.goal.steps[i].notes.length > 0) ?
                        <span className="text-muted">{this.state.goal.steps[i].notes}</span> : '';
                    let date = this.moment(this.state.goal.steps[i].added).format('MM/DD/YY');
                    progress.push(<tr key={`step-${i}`}><th className="text-right">{amount}</th><td>{date}<br />{notes}</td></tr>);
                }
            }

        }
        
        return (
            <div className="ViewGoal">
                {this.state.goal && <Fragment>
                    <h1>{this.state.goal.title}</h1>
                    {lead}
                    <div className="display-cats">{cats}</div>
                    <div className="display-progress pt-5 pb-3">
                        <h2>Goal Status <Link to={`/goals/edit/${this.state.goal.goalId}`} title="Edit Goal"><FontAwesomeIcon icon="pencil-alt" className="small" /></Link></h2>
                        <p>
                            This goal is currently {this.state.goal.status}<br />
                            Each cat is {parseInt(this.state.goal.size).toLocaleString()} {this.state.goal.units}<br />
                            {this.state.goal.progress.cats} completed of {this.state.goal.cats} cats<br />
                            {parseInt(this.state.goal.progress.progress).toLocaleString()} completed of {parseInt(this.state.goal.target).toLocaleString()} {this.state.goal.units}
                        </p>
                    </div>
                    <h2>Progress <Link to={`/progress/${this.state.goal.goalId}`} title="Add Progress"><FontAwesomeIcon icon="plus-circle" className="small" /></Link></h2>
                    <Table striped borderless="true"><tbody>{progress}</tbody></Table>
                </Fragment>}
            </div>
        );
    }
}
